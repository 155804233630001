import React from "react"

import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import "./clients.css"
import { clientPhoto1, anytimeLogoBlack } from "../assets"

const content = [
  {
    clientPhoto: clientPhoto1,
    clientName: "Алмаз Ахпаров",
    clientPosition: {
      title: "Генеральный директор",
      link: {
        title: "Anytime.kz",
        url: "https://anytime.kz/",
      },
    },
    clientReview:
      "В автопарке каршеринга 340 автомобилей. Ежедневно совершается более 2000 аренд. Раньше приходилось каждый день тратить несколько часов на проверку для того, чтобы не пропустить штрафы. Сейчас Anytime купил подписку от ОКautо Business и спокоен потому, что вовремя получает информацию о штрафах.",
    companyLogo: anytimeLogoBlack,
  },
]

const ClientReviews = () => {
  return (
    <Slider
      dots={true}
      infinite={true}
      speed={500}
      slidesToShow={1}
      slidesToScroll={1}
    >
      {content.map((item, index) => (
        <div
          key={index}
          className="clients__reviews__slide gray_border relative"
        >
          <div className="flex items-center mb4-l mb3">
            <img
              alt="client1"
              title="client1"
              src={item.clientPhoto}
              className="client__photo"
            />
            <div className="pv3-l">
              <h5 className="f3-l f5 ma0">{item.clientName}</h5>
              <p className="ma0 f4-l f7">
                {item.clientPosition.title}{" "}
                {item.clientPosition.link && (
                  <a href={item.clientPosition.link.url} className="blue link">
                    {item.clientPosition.link.title}
                  </a>
                )}
              </p>
            </div>
          </div>
          <p className="f4-l f7">{item.clientReview}</p>
          <img
            alt="company logo"
            title="company logo"
            src={item.companyLogo}
            className="company__logo ma0 dn db-l"
          />
        </div>
      ))}
    </Slider>
  )
}

export default ClientReviews
