import React, { Component } from "react"
import { Layout, SEO } from "../components"
import {
  tick,
  partner1,
  partner3,
  arrowBottom,
  taxiPool,
  leasing,
  logistics,
  others,
  calendar2,
  bell,
  search,
  search2,
  integration,
  web,
  controlling,
  processing,
  paperYellow,
  economy,
  manualChecking,
  time,
  pay,
  paper,
  car,
  pc,
  notification,
  logoDHL,
  arrowRightGray,
  arrowRightBlack,
  letter,
  arLineService,
  aMotors
} from "../assets"
import "./main.css"
import "./business.css"

import ClientReviews from "../components/clients"
import ContactForm from "../components/contactForm"

export default class Business extends Component {

  state = {
    counter: 1,
    progressBarWidth: "33%",
    selectedTab: "tab1",
    question: "quest1",
    showResult: false,
    sum: 0,
    carsAmount: 0,
    selectedQuantity: ""
  }

  handleTabs = event => {
    let target = event.target;

    this.setState( {selectedTab: target.value} );
  }

  handleApproachButtons = event => {
    let target = event.target;

    if (target.classList.contains('approach__btn')) {
      target.classList.toggle( "approach__btn--active");
      target.parentNode.classList.toggle( "approach__list--opened");

    } else {
      target.parentNode.classList.toggle( "approach__btn--active");
      target.parentNode.parentNode.classList.toggle( "approach__list--opened");
    }
  }

  handleAnswers = event => {

    let step = event.target.name;
    let target = event.target;

    if (step === "step1") {
      this.setState( {
        counter: 2,
        progressBarWidth: "66%",
        question: "quest2",
      })
    }

    if (step === "step2") {
      this.setState( {
        counter: 3,
        progressBarWidth: "100%",
        question: "quest3",
        carsAmount: parseInt(target.getAttribute("data-quantity")),
        selectedQuantity: target.value
      })
    }

    if (step === "step3") {
      this.calculateSum();

      this.setState( {
        question: "",
        showResult: true
      })
    }
  }

  calculateSum = () => {
    let sum = 0;

    if (this.state.carsAmount === 10) {
      sum = 1220
    }
    if (this.state.carsAmount === 100) {
      sum = 1098
    }
    if (this.state.carsAmount === 500) {
      sum = 1037
    }
    if (this.state.carsAmount === 501) {
      sum = 854
    }

    this.setState({
      sum: sum
    }) 
  }

  resetCalc = () => {
    this.setState({
      counter: 1,
      progressBarWidth: "33%",
      question: "quest1",
      showResult: false,
      sum: 0,
      carsAmount: 0
    })
  }

  render() {
    return (
      <Layout headerColor="white" version="business">
        <SEO
          title="Контроль штрафов ПДД для бизнеса - ежедневная информация о штрафах для юридических лиц"
          description="Услуги сервиса по проверке штрафов ПДД для всего бизнеса. Это лучшее предложение для контроля нарушений ПДД и своевременной их оплаты для большого парка автомобилей (от 10 до бесконечности автомобилей)"
          keywords="Проверка штрафов для бизнеса онлайн OKauto,уведомления о штрафах, оплата штрафов"
        />

        <div className="lh-copy pages__padding animation">
          <div id="control" className="control__wrapper ph0-ns ph3">
            <div className="white pt7-l pt6 tc center w-60-l w-100 main__header__wrapper h-100 relative">
              <h1 className="main__header main__header--b2b center">
                Контролируем автоштрафы <br/> вашего бизнеса
              </h1>
              <p className="f4-l f6 mb5 mb4-l center w-60 w-100-l">
                От <span className="b">500 ₸ в месяц</span> за один автомобиль
              </p>

              <a href="#contact__us" className="link link__try black f5 tc db center pt1 pb1 b">
                Попробовать <br/> 
                <span className="f7 lh-title normal">бесплатно</span>
              </a>

              <a href="#calculator" className="db f6 no-underline white w-100 absolute left-0 right-0 bottom-0">
                <span className="db mb3">Оцените стоимость и выгоду</span>
                <img alt="arrowBottom" title="arrowBottom" src={arrowBottom} className="db center"/>
              </a>

            </div>
          </div>

          <div id="approach" className="approach__wrapper pl7-l pr6-l pv6-l pt4"> 
            <h2 className="main__header main__header--b2b tc center">Индивидуальный подход</h2>

            <div className="tabs">
              <input id="taxiPool" type="radio" name="btn" className="dn tabs__btn" onChange={this.handleTabs} value="tab1" checked={this.state.selectedTab === 'tab1'}/>
              <label htmlFor="taxiPool" title="Таксопарки" className="dib b tabs__tab flex">
                <img alt="Таксопарки" title="Таксопарки" src={taxiPool} className="h-100 ma0 mr3-l mr1"/>
                <span className="self-center flex-grow-1 f4-l">Таксопарки</span>
                <img alt="arrow right" title="arrow right" src={arrowRightGray} className="arrow_right db dn-l mb0 self-center"/>
              </label>

              <input id="leasing" type="radio" name="btn" className="dn tabs__btn" onChange={this.handleTabs} value="tab2" checked={this.state.selectedTab === 'tab2'}/>
              <label htmlFor="leasing" title="Лизинговые компании" className="dib b tabs__tab flex">
                <img alt="Лизинговые компании" title="Лизинговые компании" src={leasing} className="h-100 ma0 mr3-l mr1"/>
                <span className="self-center flex-grow-1 f4-l">Лизинговые компании</span>
                <img alt="arrow right" title="arrow right" src={arrowRightGray} className="arrow_right db dn-l mb0 self-center"/>
              </label>

              <input id="logistics" type="radio" name="btn" className="dn tabs__btn" onChange={this.handleTabs} value="tab3" checked={this.state.selectedTab === 'tab3'}/>
              <label htmlFor="logistics" title="Транспортная логистика" className="dib b tabs__tab flex">
                <img alt="Транспортная логистика" title="Транспортная логистика" src={logistics} className="h-100 ma0 mr3-l mr1"/>
                <span className="self-center flex-grow-1 f4-l">Транспортная логистика</span>
                <img alt="arrow right" title="arrow right" src={arrowRightGray} className="arrow_right db dn-l mb0 self-center"/>
              </label>
            
              <input id="others" type="radio" name="btn" className="dn tabs__btn" onChange={this.handleTabs} value="tab4" checked={this.state.selectedTab === 'tab4'}/>
              <label htmlFor="others" title="Другие организации" className="dib b tabs__tab flex">
                <img alt="Другие организации" title="Другие организации" src={others} className="h-100 ma0 mr3-l mr1"/>
                <span className="self-center flex-grow-1 f4-l">Другие организации</span>
                <img alt="arrow right" title="arrow right" src={arrowRightGray} className="arrow_right db dn-l mb0 self-center"/>
              </label>

              <div className="tabs__inner tabs__inner--desktop gray_border pa4 cb">
                <section id="taxiPool__content" className="tabs__content">
                  <div className="mb4-l mb3 overflow-hidden">
                    <img alt="Calendar" title="Calendar" src={calendar2} className="mb0 fl mr3 tabs__content_icon"/>
                    <h4 className="f7 tabs__content_header f3-l mb3 pt2-l">Ежедневная проверка на наличие штрафов каждого авто</h4>
                    <p className="cb pt2-l f5-l tabs__content_text f7">Вам больше не нужно проверять наличие штрафов вручную. Мы делаем это сами каждый день и присылаем вам отчет.</p>
                  </div>
                  <div className="mb4-l mb3 overflow-hidden">
                    <img alt="Notification" title="Notification" src={bell} className="mb0 fl mr3 tabs__content_icon"/>
                    <h4 className="f7 tabs__content_header f3-l mb3 pt2-l">Уведомление о новых нарушениях день в день</h4>
                    <p className="cb pt2-l f5-l tabs__content_text f7">Мы отправим уведомление в тот же день, когда информация о нарушении поступит в базу ЕРАП.</p>
                  </div>
                  <div className="mb4-l mb3 overflow-hidden">
                    <img alt="Search" title="Search" src={search} className="mb0 fl mr3 tabs__content_icon"/>
                    <h4 className="f7 tabs__content_header f3-l mb3 pt2-l">Возможность отследить нарушение по ИИН водителя</h4>
                    <p className="cb pt2-l f5-l tabs__content_text f7">Выполняем проверку не только по госномеру авто, но и по ИИН водителя. Это особенно актуально при постоянной смене водителей в службе такси.</p>
                  </div>
                  <div className="overflow-hidden">
                    <img alt="Integration" title="Integration" src={integration} className="mb0 fl mr3 tabs__content_icon"/>
                    <h4 className="f7 tabs__content_header f3-l mb3 pt2-l">Приложение интегрируется с вашей системой</h4>
                    <p className="cb pt2-l f5-l tabs__content_text f7">Мы интегрируем сервис в вашу систему, чтобы нам поступали данные об автомобилях, а вам - информация о штрафах. </p>
                  </div>
                  <a href="#contact__us" className="link link__try black f5 tc db center pt1 pb1 b">
                    Попробовать <br/> 
                    <span className="f7 lh-title normal">бесплатно</span>
                  </a>
                </section>
                <section id="leasing__content" className="tabs__content">
                  <div className="mb4-l mb3 overflow-hidden">
                    <img alt="Calendar" title="Calendar" src={calendar2} className="mb0 fl mr3 tabs__content_icon"/>
                    <h4 className="f7 tabs__content_header f3-l mb3 pt2-l">Ежедневный поиск штрафов по всем зарегистрированным авто</h4>
                    <p className="cb pt2-l f5-l tabs__content_text f7">Экономьте рабочее время своих сотрудников. Наша система проводит ежедневный автоматический мониторинг нарушений, которые уже попали в базу ЕРАП.</p>
                  </div>
                  <div className="mb4-l mb3 overflow-hidden">
                    <img alt="System" title="System" src={web} className="mb0 fl mr3 tabs__content_icon"/>
                    <h4 className="f7 tabs__content_header f3-l mb3 pt2-l">Информация собрана в одной системе</h4>
                    <p className="cb pt2-l f5-l tabs__content_text f7">А значит вы избежите ошибок и путаницы, как бывает при ведении бумажной документации.</p>
                  </div>
                  <div className="mb4-l mb3 overflow-hidden">
                    <img alt="Controlling" title="Controlling" src={controlling} className="mb0 fl mr3 tabs__content_icon"/>
                    <h4 className="f7 tabs__content_header f3-l mb3 pt2-l">Контролируйте погашение штрафов</h4>
                    <p className="cb pt2-l f5-l tabs__content_text f7">Все данные о штрафах лизингополучателей хранятся в одной системе, поэтому вы сможете отследить их от момента, когда было совершено нарушение, до его оплаты.</p>
                  </div>
                  <div className="mb4-l mb3 overflow-hidden">
                    <img alt="Integration" title="Integration" src={integration} className="mb0 fl mr3 tabs__content_icon"/>
                    <h4 className="f7 tabs__content_header f3-l mb3 pt2-l">Интеграция с вашей системой</h4>
                    <p className="cb pt2-l f5-l tabs__content_text f7">Интегрируем приложение в СRM вашей компании. При обнаружении штрафа уведомление будет приходить прямо в вашу систему и вы точно его не пропустите.</p>
                  </div>
                  <div className="overflow-hidden">
                    <img alt="Processing" title="Processing" src={processing} className="mb0 fl mr3 tabs__content_icon"/>
                    <h4 className="f7 tabs__content_header f3-l mb3 pt2-l">Обрабатываем данные о нарушении, как только оно попало в базу ЕРАП</h4>
                    <p className="cb pt2-l f5-l tabs__content_text f7">Как только система обнаруживает штраф, вашему клиенту поступает уведомление. Это дает ему возможность погасить штраф в первые 7 дней со скидкой 50% и повышает уровень доверия вашей компании.</p>
                  </div>
                  <a href="#contact__us" className="link link__try black f5 tc db center pt1 pb1 b">
                    Попробовать <br/> 
                    <span className="f7 lh-title normal">бесплатно</span>
                  </a>
                </section>
                <section id="logistics__content" className="tabs__content">
                  <div className="mb4-l mb3 overflow-hidden">
                    <img alt="Search" title="Search" src={search2} className="mb0 fl mr3 tabs__content_icon"/>
                    <h4 className="f7 tabs__content_header f3-l mb3 pt2-l">Определим нарушителя по дате и времени нарушения</h4>
                    <p className="cb pt2-l f5-l tabs__content_text f7">Вам не нужно искать ответственного за нарушение. Мы сами отправим водителю уведомление о штрафе, чтобы он мог его погасить сразу же, перейдя по ссылке.</p>
                  </div>
                  <div className="mb4-l mb3 overflow-hidden">
                    <img alt="Reports" title="Reports" src={paperYellow} className="mb0 fl mr3 tabs__content_icon"/>
                    <h4 className="f7 tabs__content_header f3-l mb3 pt2-l">Ежемесячные отчеты с данными о штрафах и их погашении</h4>
                    <p className="cb pt2-l f5-l tabs__content_text f7">Получайте отчет по всем штрафам и нарушителям в файле Microsoft Excel.</p>
                  </div>
                  <div className="overflow-hidden">
                    <img alt="System" title="System" src={web} className="mb0 fl mr3 tabs__content_icon"/>
                    <h4 className="f7 tabs__content_header f3-l mb3 pt2-l">Вся информация хранится в одной системе</h4>
                    <p className="cb pt2-l f5-l tabs__content_text f7">Это упростит работу ваших сотрудников и исключит ошибки при обработке бумажных постановлений.</p>
                  </div>
                  <a href="#contact__us" className="link link__try black f5 tc db center pt1 pb1 b">
                    Попробовать <br/> 
                    <span className="f7 lh-title normal">бесплатно</span>
                  </a>
                </section>
                <section id="others__content" className="tabs__content">
                  <div className="mb4-l mb3 overflow-hidden">
                    <img alt="Notification" title="Notification" src={bell} className="mb0 fl mr3 tabs__content_icon"/>
                    <h4 className="f7 tabs__content_header f3-l mb3 pt2-l">Больше не нужно проверять авто на наличие штрафов</h4>
                    <p className="cb pt2-l f5-l tabs__content_text f7">Мы делаем это сами каждый день и пришлем вам уведомление, если обнаружим нарушение.</p>
                  </div>
                  <div className="mb4-l mb3 overflow-hidden">
                    <img alt="Economy" title="Economy" src={economy} className="mb0 fl mr3 tabs__content_icon"/>
                    <h4 className="f7 tabs__content_header f3-l mb3 pt2-l">Экономьте 50% на оплате штрафов</h4>
                    <p className="cb pt2-l f5-l tabs__content_text f7">Вы получаете уведомление о штрафе в день, когда он попадает в базу ЕРАП. Это дает возможность нарушителю оплатить его в первые 7 дней со скидкой 50%</p>
                  </div>
                  <div className="overflow-hidden">
                    <img alt="System" title="System" src={web} className="mb0 fl mr3 tabs__content_icon"/>
                    <h4 className="f7 tabs__content_header f3-l mb3 pt2-l">Вся информация о водителях, авто и штрафах хранится в одной системе</h4>
                    <p className="cb pt2-l f5-l tabs__content_text f7">Вы всегда сможете получить доступ к данным и не запутаться</p>
                  </div>
                  <a href="#contact__us" className="link link__try black f5 tc db center pt1 pb1 b">
                    Попробовать <br/> 
                    <span className="f7 lh-title normal">бесплатно</span>
                  </a>
                </section>
              </div>

              <div className="cb dn-l db">
                <div className="approach__list">
                  <button className="approach__btn dn outline-0" onClick={this.handleApproachButtons}> 
                    <span className="arrow__left tc"></span>
                    <img alt="Таксопарки" title="Таксопарки" src={taxiPool} className="h2 ma0 mr3-l mr1"/>
                    <span className="approach__btn_title tl self-center">Таксопарки</span>
                    <img alt="arrow right" title="arrow right" src={arrowRightGray} className="arrow__right db dn-l mb0 self-center"/>
                  </button>
                  <div className="approach__content">
                    <div className="mb4-l mb3 overflow-hidden">
                      <img alt="Calendar" title="Calendar" src={calendar2} className="mb0 fl mr3 tabs__content_icon"/>
                      <h4 className="f7 f5-ns f3-l mb3 pt2-l">Ежедневная проверка на наличие штрафов каждого авто</h4>
                      <p className="cb pt2-l f5-l f6-ns f7">Вам больше не нужно проверять наличие штрафов вручную. Мы делаем это сами каждый день и присылаем вам отчет.</p>
                    </div>
                    <div className="mb4-l mb3 overflow-hidden">
                      <img alt="Notification" title="Notification" src={bell} className="mb0 fl mr3 tabs__content_icon"/>
                      <h4 className="f7 f5-ns f3-l mb3 pt2-l">Уведомление о новых нарушениях день в день</h4>
                      <p className="cb pt2-l f5-l f6-ns f7">Мы отправим уведомление в тот же день, когда информация о нарушении поступит в базу ЕРАП.</p>
                    </div>
                    <div className="mb4-l mb3 overflow-hidden">
                      <img alt="Search" title="Search" src={search} className="mb0 fl mr3 tabs__content_icon"/>
                      <h4 className="f7 f5-ns f3-l mb3 pt2-l">Возможность отследить нарушение по ИИН водителя</h4>
                      <p className="cb pt2-l f5-l f6-ns f7">Выполняем проверку не только по госномеру авто, но и по ИИН водителя. Это особенно актуально при постоянной смене водителей в службе такси.</p>
                    </div>
                    <div className="overflow-hidden">
                      <img alt="Integration" title="Integration" src={integration} className="mb0 fl mr3 tabs__content_icon"/>
                      <h4 className="f7 f5-ns f3-l mb3 pt2-l">Приложение интегрируется с вашей системой</h4>
                      <p className="cb pt2-l f5-l f6-ns f7">Мы интегрируем сервис в вашу систему, чтобы нам поступали данные об автомобилях, а вам - информация о штрафах. </p>
                    </div>
                    <a href="#contact__us" className="link link__try black f5 tc db center pt1 pb1 b">
                      Попробовать <br/> 
                      <span className="f7 lh-title normal">бесплатно</span>
                    </a>
                  </div>
                </div>
                
                <div className="approach__list">
                  <button className="approach__btn dn gray_border outline-0" onClick={this.handleApproachButtons}> 
                    <span className="arrow__left tc"></span>
                    <img alt="Лизинговые компании" title="Лизинговые компании" src={leasing} className="h2 ma0 mr3-l mr1"/>
                    <span className="approach__btn_title tl self-center">Лизинговые компании</span>
                    <img alt="arrow right" title="arrow right" src={arrowRightGray} className="arrow__right db dn-l mb0 self-center"/>
                  </button>
                  <div className="approach__content">
                    <div className="mb4-l mb3 overflow-hidden">
                      <img alt="Calendar" title="Calendar" src={calendar2} className="mb0 fl mr3 tabs__content_icon"/>
                      <h4 className="f7 f5-ns f3-l mb3 pt2-l">Ежедневный поиск штрафов по всем зарегистрированным авто</h4>
                      <p className="cb pt2-l f5-l f6-ns f7">Экономьте рабочее время своих сотрудников. Наша система проводит ежедневный автоматический мониторинг нарушений, которые уже попали в базу ЕРАП.</p>
                    </div>
                    <div className="mb4-l mb3 overflow-hidden">
                      <img alt="System" title="System" src={web} className="mb0 fl mr3 tabs__content_icon"/>
                      <h4 className="f7 f5-ns f3-l mb3 pt2-l">Информация собрана в одной системе</h4>
                      <p className="cb pt2-l f5-l f6-ns f7">А значит вы избежите ошибок и путаницы, как бывает при ведении бумажной документации.</p>
                    </div>
                    <div className="mb4-l mb3 overflow-hidden">
                      <img alt="Controlling" title="Controlling" src={controlling} className="mb0 fl mr3 tabs__content_icon"/>
                      <h4 className="f7 f5-ns f3-l mb3 pt2-l">Контролируйте погашение штрафов</h4>
                      <p className="cb pt2-l f5-l f6-ns f7">Все данные о штрафах лизингополучателей хранятся в одной системе, поэтому вы сможете отследить их от момента, когда было совершено нарушение, до его оплаты.</p>
                    </div>
                    <div className="mb4-l mb3 overflow-hidden">
                      <img alt="Integration" title="Integration" src={integration} className="mb0 fl mr3 tabs__content_icon"/>
                      <h4 className="f7 f5-ns f3-l mb3 pt2-l">Интеграция с вашей системой</h4>
                      <p className="cb pt2-l f5-l f6-ns f7">Интегрируем приложение в СRM вашей компании. При обнаружении штрафа уведомление будет приходить прямо в вашу систему и вы точно его не пропустите.</p>
                    </div>
                    <div className="overflow-hidden">
                      <img alt="Processing" title="Processing" src={processing} className="mb0 fl mr3 tabs__content_icon"/>
                      <h4 className="f7 f5-ns f3-l mb3 pt2-l">Обрабатываем данные о нарушении, как только оно попало в базу ЕРАП</h4>
                      <p className="cb pt2-l f5-l f6-ns f7">Как только система обнаруживает штраф, вашему клиенту поступает уведомление. Это дает ему возможность погасить штраф в первые 7 дней со скидкой 50% и повышает уровень доверия вашей компании.</p>
                    </div>
                    <a href="#contact__us" className="link link__try black f5 tc db center pt1 pb1 b">
                      Попробовать <br/> 
                      <span className="f7 lh-title normal">бесплатно</span>
                    </a>
                  </div>
                </div>
                  
                <div className="approach__list">
                  <button className="approach__btn dn gray_border outline-0" onClick={this.handleApproachButtons}> 
                    <span className="arrow__left tc"></span>
                    <img alt="Транспортная логистика" title="Транспортная логистика" src={logistics} className="h2 ma0 mr3-l mr1"/>
                    <span className="approach__btn_title tl self-center">Транспортная логистика</span>
                    <img alt="arrow right" title="arrow right" src={arrowRightGray} className="arrow__right db dn-l mb0 self-center"/>
                  </button>
                  <div className="approach__content">
                    <div className="mb4-l mb3 overflow-hidden">
                      <img alt="Search" title="Search" src={search2} className="mb0 fl mr3 tabs__content_icon"/>
                      <h4 className="f7 f5-ns f3-l mb3 pt2-l">Определим нарушителя по дате и времени нарушения</h4>
                      <p className="cb pt2-l f5-l f6-ns f7">Вам не нужно искать ответственного за нарушение. Мы сами отправим водителю уведомление о штрафе, чтобы он мог его погасить сразу же, перейдя по ссылке.</p>
                    </div>
                    <div className="mb4-l mb3 overflow-hidden">
                      <img alt="Reports" title="Reports" src={paperYellow} className="mb0 fl mr3 tabs__content_icon"/>
                      <h4 className="f7 f5-ns f3-l mb3 pt2-l">Ежемесячные отчеты с данными о штрафах и их погашении</h4>
                      <p className="cb pt2-l f5-l f6-ns f7">Получайте отчет по всем штрафам и нарушителям в файле Microsoft Excel.</p>
                    </div>
                    <div className="overflow-hidden">
                      <img alt="System" title="System" src={web} className="mb0 fl mr3 tabs__content_icon"/>
                      <h4 className="f7 f5-ns f3-l mb3 pt2-l">Вся информация хранится в одной системе</h4>
                      <p className="cb pt2-l f5-l f6-ns f7">Это упростит работу ваших сотрудников и исключит ошибки при обработке бумажных постановлений.</p>
                    </div>
                    <a href="#contact__us" className="link link__try black f5 tc db center pt1 pb1 b">
                      Попробовать <br/> 
                      <span className="f7 lh-title normal">бесплатно</span>
                    </a>
                  </div>
                </div>

                <div className="approach__list">
                  <button className="approach__btn dn gray_border outline-0" onClick={this.handleApproachButtons}> 
                    <span className="arrow__left tc"></span>
                    <img alt="Другие организации" title="Другие организации" src={others} className="h2 ma0 mr3-l mr1"/>
                    <span className="approach__btn_title tl self-center">Другие организации</span>
                    <img alt="arrow right" title="arrow right" src={arrowRightGray} className="arrow__right db dn-l mb0 self-center"/>
                  </button>
                  <div className="approach__content">
                    <div className="mb4-l mb3 overflow-hidden">
                      <img alt="Notification" title="Notification" src={bell} className="mb0 fl mr3 tabs__content_icon"/>
                      <h4 className="f7 f5-ns f3-l mb3 pt2-l">Больше не нужно проверять авто на наличие штрафов</h4>
                      <p className="cb pt2-l f5-l f6-ns f7">Мы делаем это сами каждый день и пришлем вам уведомление, если обнаружим нарушение.</p>
                    </div>
                    <div className="mb4-l mb3 overflow-hidden">
                      <img alt="Economy" title="Economy" src={economy} className="mb0 fl mr3 tabs__content_icon"/>
                      <h4 className="f7 f5-ns f3-l mb3 pt2-l">Экономьте 50% на оплате штрафов</h4>
                      <p className="cb pt2-l f5-l f6-ns f7">Вы получаете уведомление о штрафе в день, когда он попадает в базу ЕРАП. Это дает возможность нарушителю оплатить его в первые 7 дней со скидкой 50%</p>
                    </div>
                    <div className="overflow-hidden">
                      <img alt="System" title="System" src={web} className="mb0 fl mr3 tabs__content_icon"/>
                      <h4 className="f7 f5-ns f3-l mb3 pt2-l">Вся информация о водителях, авто и штрафах хранится в одной системе</h4>
                      <p className="cb pt2-l f5-l f6-ns f7">Вы всегда сможете получить доступ к данным и не запутаться</p>
                    </div>
                    <a href="#contact__us" className="link link__try black f5 tc db center pt1 pb1 b">
                      Попробовать <br/> 
                      <span className="f7 lh-title normal">бесплатно</span>
                    </a>
                  </div>
                </div>
              </div>  
            </div>
          </div>

          <div id="makeBetter" className="we__makeBetter__wrapper pt5 pt0-l pb6-l">
            <h2 className="main__header main__header--b2b tc center">Мы улучшаем работу бизнеса</h2>
            <div className="we__makeBetter__inner center flex flex-column pt4 pb4">
                
              <div className="flex justify-between">
                <h4 className="f3-l f6 w-40">Без нас</h4>
                <h4 className="f3-l f6 w-40">С нами</h4>
              </div>

              <div className="flex">
                <div className="w-40"><img alt="Manual Checking" title="Manual Checking" src={manualChecking} className="ma0 we_makeBetter_icon"/></div>
                <div className="w-20"><img alt="arrow right" title="arrow right" src={arrowRightBlack} className="ma0 ml3 ml5-l"/></div>
                <div className="w-40"><img alt="Car" title="Car" src={car} className="ma0 we_makeBetter_icon"/></div>
              </div>

              <div className="flex justify-between">
                <p className="f4-l f7 w-40">Проверка каждого авто вручную</p>
                <p className="f4-l f7 w-40">Система проверяет наличие штрафов автоматически</p>
              </div>

              <div className="flex">
                <div className="w-40"><img alt="Time" title="Time" src={time} className="ma0 we_makeBetter_icon"/></div>
                <div className="w-20"><img alt="arrow right" title="arrow right" src={arrowRightBlack} className="ma0 ml3 ml5-l"/></div>
                <div className="w-40"><img alt="Online pay" title="Online Pay" src={pc} className="ma0 we_makeBetter_icon"/></div>
                
              </div>

              <div className="flex justify-between">
                <p className="f4-l f7 w-40">Трата времени на обработку и оплату штрафов</p>
                <p className="f4-l f7 w-40">Оплата в два клика онлайн</p>
              </div>

              <div className="flex">
                <div className="w-40"><img alt="Pay" title="Pay" src={pay} className="ma0 we_makeBetter_icon"/></div>
                <div className="w-20"><img alt="arrow right" title="arrow right" src={arrowRightBlack} className="ma0 ml3 ml5-l"/></div>
                <div className="w-40"><img alt="Notification" title="Notification" src={notification} className="ma0 we_makeBetter_icon"/></div>
              </div>
                
              <div className="flex justify-between">
                <p className="f4-l f7 w-40">Переплата за просроченные штрафы</p>
                <p className="f4-l f7 w-40">Уведомление о штрафах день в день и скидка 50% на их оплату</p>
              </div>

              <div className="flex">
                <div className="w-40"><img alt="Papers" title="Papers" src={paper} className="ma0 we_makeBetter_icon"/></div>
                <div className="w-20"><img alt="arrow right" title="arrow right" src={arrowRightBlack} className="ma0 ml3 ml5-l"/></div>
                <div className="w-40"><img alt="System" title="System" src={web} className="ma0 we_makeBetter_icon"/></div>
              </div>

              <div className="flex justify-between">
                <p className="f4-l f7 w-40">Бумажная работа со штрафами</p>
                <p className="f4-l f7 w-40">Вся информация о штрафах и нарушителях в одном месте</p>
              </div>
            </div>
            <a href="#contact__us" className="link link__try black f5 tc db center pt1 pb1 b">
              Попробовать <br/> 
              <span className="f7 lh-title normal">бесплатно</span>
            </a>
          </div>

          <div id="easyStart" className="easy__start__wrapper ph5-l pb6-l pt5">
            <h2 className="main__header main__header--b2b tc mb0 center">Начать просто</h2>
            <div className="flex justify-around pv5-l pv3 flex-row-l flex-column">
              <div className="w-30-l">
                <div className="numbers tc b center">1</div>
                <p className="tc f4-l f6-ns f7"><a href="#contact__us" className="no-underline" style={{ color: "#FFCC29"}}>Оставьте заявку</a>, и мы перезвоним или позвоните нам <a href="tel:+7 778 133 8042" className="b db di-l no-underline black dim">+7 778 133 8042</a></p>
              </div>
              <div className="w-30-l">
                <div className="numbers tc b center">2</div>
                <p className="tc f4-l f6-ns f7">Мы пригласим вас в веб-сервис</p>
              </div>
              <div className="w-30-l">
                <div className="numbers tc b center">3</div>
                  <p className="tc f4-l f6-ns f7">Вы получите доступ в личный кабинет, где сможете добавить автомобили и следить за штрафами</p>
                </div>
            </div>

            <a href="#contact__us" className="link link__try black f5 tc center pt1 pb1 b dn db-l">
              Попробовать <br/> 
              <span className="f7 lh-title normal">бесплатно</span>
            </a>
          </div>

          <div id="clients" className="clients__wrapper pv5 pb6-l">
            <h2 className="main__header main__header--b2b tc mb0-l center">Клиенты</h2>
            <div className="сompanys_small_screen">
              <div className="companys overflow-hidden relative mb0-l">
                <div className="companys__inner h-100 static-l absolute top-0 left-0 right-0 bottom-0 flex-l justify-around-l pv5-l"> 
                  <img alt="partnerDHL" title="partnerDHL" src={logoDHL} className="mb0 h-100 mr3 wide_image"/>
                  <img alt="Vernyi" title="Vernyi" src={partner1} className="mb0 h-100 mr3 wide_image"/>
                  <img alt="Anytime" title="Anytime" src={partner3} className="mb0 h-100 mr3 wide_image" />
                  <img alt="aMotors" title="aMotors" src={aMotors} className="mb0 h-100 mr3 wide_image" />
                  <img alt="arLineService" title="arLineService" src={arLineService} className="mb0 h-100 wide_image"/>
                </div>
              </div>
            </div>

            <div className="сompanys_not_small_screen">
              <div className="companys overflow-hidden relative mb0-l">
                <div className="companys__inner h-100 static-l absolute top-0 left-0 right-0 bottom-0 flex-l justify-around-l pv5-l"> 
                  <img alt="partnerDHL" title="partnerDHL" src={logoDHL} className="mb0 h-100 mr3 wide_image"/>
                  <img alt="Vernyi" title="Vernyi" src={partner1} className="mb0 h-100 mr3 wide_image"/>
                  <img alt="Anytime" title="Anytime" src={partner3} className="mb0 h-100 mr3 wide_image" />
                  </div>
              </div>
              <div className="companys overflow-hidden relative mb0-l">
                <div className="companys__inner h-100 static-l absolute top-0 left-0 right-0 bottom-0 flex-l justify-around-l pv5-l"> 
                  <img alt="aMotors" title="aMotors" src={aMotors} className="mb0 h-100 mr3 tall_image" />
                  <img alt="arLineService" title="arLineService" src={arLineService} className="mb0 h-100 tall_image"/>
                </div>
              </div>
            </div>
            <ClientReviews />
          </div>

          <div className="calculator__wrapper pv5">
            <div id="calculator">
              <h2 className="main__header main__header--b2b tc mb5-l center">Оцените стоимость и выгоду для бизнеса</h2>
              <div className="calculator center gray_border" style={{display: !this.state.showResult ? "block" : "none"}}>
                <div className="progress_bar flex mb4">
                  <p className="progress_bar__counter ma0 b mr4-l mr2 f4">{this.state.counter} / 3</p>
                  <div className="progress_bar__outer flex-grow-1">
                    <div className="progress_bar__inner"
                        style={{width: this.state.progressBarWidth}}></div>
                  </div>
                </div>
                <div style={{display: this.state.question === "quest1" ? "block" : "none"}}>
                  <h3 className="calculator__quest">Какой у вас бизнес?</h3>
                  <input type="button" name="step1" value="Такси" className="calculator__btn gray_border tc b dib" onClick={this.handleAnswers}/>
                  <input type="button" name="step1" value="Лизинг" className="calculator__btn gray_border tc b dib" onClick={this.handleAnswers}/>
                  <input type="button" name="step1" value="Логистика" className="calculator__btn gray_border tc b dib" onClick={this.handleAnswers}/>
                  <input type="button" name="step1" value="Другой" className="calculator__btn gray_border tc b dib" onClick={this.handleAnswers}/>
                </div>
                <div style={{display: this.state.question === "quest2" ? "block" : "none"}}>
                  <h3 className="calculator__quest">Сколько автомобилей в вашей компании?</h3>
                  <input type="button" name="step2" value="1-10" data-quantity="10" className="calculator__btn gray_border tc b dib" onClick={this.handleAnswers}/>
                  <input type="button" name="step2" value="11-100" data-quantity="100" className="calculator__btn gray_border tc b dib" onClick={this.handleAnswers}/>
                  <input type="button" name="step2" value="101-500" data-quantity="500" className="calculator__btn gray_border tc b dib" onClick={this.handleAnswers}/>
                  <input type="button" name="step2" value="Более 500" data-quantity="501" className="calculator__btn gray_border tc b dib" onClick={this.handleAnswers}/>
                </div>
                <div style={{display: this.state.question === "quest3" ? "block" : "none"}}>
                  <h3 className="calculator__quest">Куда вам присылать уведомления о новом штрафе?</h3>
                  <button name="step3" className="calculator__btn calculator__btn--email gray_border tc b dib" onClick={this.handleAnswers}>
                    <img alt="email" title="email" src={letter} className="mb0 mr2"/>На почту
                  </button>
                  <input type="button" name="step3" value="Во внутреннюю систему вашей компании" className="calculator__btn gray_border tc b dib ws-normal" onClick={this.handleAnswers}/>
                </div>
              </div>

              <div className="calculator__result flex flex-row-l flex-column gray_border w-80-l center" style={{display: this.state.showResult ? "flex" : "none"}}>
                <div className="calculator__result_left tc w-50-l pt6-l pv4 pb0-l relative">
                  <p className="f3-l f5-ns f7 ma0">Стоимость услуги</p>
                  <p className="f2-l f3-ns f5 b mb5"><span id="calcResult">{this.state.sum}</span> ₸ за авто / месяц</p>
                  <a href="#contact__us" className="link link__try black f5 tc db center pt1 pb1 b mb5">
                    Попробовать <br/> 
                    <span className="f7 lh-title normal">бесплатно</span>
                  </a>
                  <div className="absolute-l bottom-2 tc w-100 reset_btn pointer f5-l f6" style={{color : "#FFCC29"}} onClick={this.resetCalc}>Оценить стоимость еще раз</div>
                </div>
                
                <div className="calculator__result_right w-50-l pa3 pa4-l">
                  <p className="o-60 f5-l f6-ns f7">В результате у вас будет</p>
                  <div>
                    <img alt="tick" title="tick" src={tick} className="ma0 fl mr2 tick"/>
                    <h4 className="fl lh-copy mb3 f5-l f6-ns f7">Больше никаких «писем счастья»</h4>
                    <p className="cb f5-l f6-ns f7">Мы отправим уведомление о найденном штрафе в день, когда нарушение попадет в базу. </p>
                  </div>
                  <div>
                    <img alt="tick" title="tick" src={tick} className="ma0 fl mr2 tick"/>
                    <h4 className="fl lh-copy mb3 f5-l f6-ns f7">Гарантия погашения штрафов</h4>
                    <p className="cb f5-l f6-ns f7">Мы гарантируем, что после оплаты штрафа деньги будут перечислены в казначейство Республики Казахстан и предоставим квитанцию.</p>
                  </div>
                  <div>
                    <img alt="tick" title="tick" src={tick} className="ma0 fl mr2 tick"/>
                    <h4 className="fl lh-copy mb3 f5-l f6-ns f7">Интеграция в вашу систему</h4>
                    <p className="cb f5-l f6-ns f7">Если у вашей компании есть CRM-система, мы интегрируем в неё свой продукт. Вся информация об автомобилях и водителях будет у нас, а к вам будут поступать только данные о нарушениях.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="contact__form__wrapper">
            <h2 className="main__header main__header--b2b center tc">Готовы сотрудничать?</h2>
            <ContactForm selectedQuantity={this.state.selectedQuantity}/>
          </div>
        </div>
      </Layout>
    )
  }
}
