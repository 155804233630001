import React, { Component } from "react"
import InputMask from "react-input-mask"
import {
  igBlack,
  vkBlack,
  fbBlack,
  ybBlack,
  wpBlack,
  tickLarge,
  closeNew,
  ios4,
  ad4
} from "../assets"
import "./contactForm.css"
import ApolloClient from "apollo-boost"
import gql from "graphql-tag"
import fetch from "isomorphic-fetch"

const client = new ApolloClient({
  uri: process.env.API_LIVE_URL,
  fetch,
})

class ContactForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      inputs: {},
      errors: {},
      selectedQuantity: "",
      showPopup: false,
      loading: false,
      disabled: true,
      smallBusiness: false
    }
  }

  componentWillReceiveProps(props) {
    if (props.selectedQuantity !== this.state.selectedQuantity) {
      this.setState({ selectedQuantity: props.selectedQuantity });
    }
  }

  handleApplySend = async () => {
    try {
      const res = await client.mutate({
        variables: {
          input: {
            companyName: this.state.inputs["companyName"],
            carParkSize: this.state.inputs["quantity"],
            contactName: this.state.inputs["clientName"],
            contactPhone: this.state.inputs["phoneNumber"],
          },
        },
        mutation: gql`
          mutation applySend(
            $input: ApplySendInput = {
              companyName: ""
              carParkSize: ""
              contactName: ""
              contactPhone: ""
            }
          ) {
            applySend(input: $input) {
              success
            }
          }
        `,
      })

      if (res === false) {
        alert(
          "Не удалось отправить заявку, пожалуйста, попробуйте позднее или свяжитесь с нами по указанному номеру телефона: +7 778 133 8042"
        )

        return res
      }

      return res
    } catch {
      alert(
        "Не удалось отправить заявку, пожалуйста, попробуйте позднее или свяжитесь с нами по указанному номеру телефона: +7 778 133 8042"
      )
    }
  }

  handleValidation = () => {
    let inputs = this.state.inputs
    let errors = {}
    let formIsValid = true

    if (!inputs["clientName"]) {
      formIsValid = false
      errors["clientName"] = "Введите имя"
    } else if (!inputs["clientName"].match(/^[a-zA-Z]+|[а-яёА-Я]+$/)) {
      formIsValid = false
      errors["clientName"] = "Введите корректное имя"
    }

    if (!inputs["companyName"]) {
      formIsValid = false
      errors["companyName"] = "Введите название организации"
    }

    if (!inputs["phoneNumber"]) {
      formIsValid = false
      errors["phoneNumber"] = "Введите номер телефона"
    }

    if (!inputs["quantity"]) {
      formIsValid = false
      errors["quantity"] = "Выберите размер автопарка"
    }

    this.setState({ errors: errors })
    return formIsValid
  }

  handleContactInfo = event => {
    let target = event.target

    this.setState(({ inputs }) => ({
      inputs: {
        ...inputs,
        [target.name]: target.value,
      },
    }))
  }

  chooseQuantity = event => {
    let target = event.target

    this.setState(({ inputs }) => ({
      inputs: {
        ...inputs,
        [target.name]: target.value,
      },
      selectedQuantity: target.value,
      smallBusiness: false
    }))
  }

  handleSubmit = async event => {
    /* данные формы для отправки */
    //this.state.inputs
    event.preventDefault()
    if (!this.state.loading && this.handleValidation()) {
      this.setState({ loading: true })
      const applicationSent = await this.handleApplySend()

      if (applicationSent) {
        this.setState({ showPopup: true })
      }
      this.setState({ loading: false })
    }
  }

  closePopup = () => {
    this.setState({ showPopup: false })
  }

  openSmallBusinessForm = (e) => {
    this.setState({
      smallBusiness: true
    })
  }

  render() {
    return (
      <div>
        <form
          id="contact__us"
          className="contact__form center bg-white"
          onSubmit={this.handleSubmit}
        >
          <div className="contact__form_inner">
            <p className="mb2 f5-l f6">Название организации </p>
            <input
              type="text"
              name="companyName"
              placeholder="Введите здесь"
              className="contact__form_input w-100 outline-0 bn pa3 f5-l f6"
              onChange={this.handleContactInfo}
            />
            <p className="f6 ma0 mb3 red">{this.state.errors["companyName"]}</p>

            <p className="mb2 f5-l f6">Размер автопарка</p>
            <div className="flex justify-between mb1 flex-row flex-wrap">
              <input
                type="radio"
                name="quantity"
                id="ten"
                className="contact__form_btn"
                onChange={this.chooseQuantity}
                value="1-10"
                checked={this.state.selectedQuantity === "1-10"}
              />
              <label
                htmlFor="ten"
                title="1-10"
                className="contact__form_lbl gray_border mr3-l mb3 mb0-l tc b"
              >
                1-10
              </label>

              <input
                type="radio"
                name="quantity"
                id="hundred"
                className="contact__form_btn"
                onChange={this.chooseQuantity}
                value="11-100"
                checked={this.state.selectedQuantity === "11-100"}
              />
              <label
                htmlFor="hundred"
                title="11-100"
                className="contact__form_lbl gray_border mr3-l mb3 mb0-l tc b"
              >
                11-100
              </label>

              <input
                type="radio"
                name="quantity"
                id="fiveHundreds"
                className="contact__form_btn"
                onChange={this.chooseQuantity}
                value="101-500"
                checked={this.state.selectedQuantity === "101-500"}
              />
              <label
                htmlFor="fiveHundreds"
                title="101-500"
                className="contact__form_lbl gray_border mr3-l mb0-l tc b"
              >
                101-500
              </label>

              <input
                type="radio"
                name="quantity"
                id="fiveHundredsPlus"
                className="contact__form_btn"
                onChange={this.chooseQuantity}
                value="500+"
                checked={this.state.selectedQuantity === "Более 500"}
              />
              <label
                htmlFor="fiveHundredsPlus"
                title="Более 500"
                className="contact__form_lbl gray_border tc b"
              >
                Более 500
              </label>
            </div>
            <p className="f6 ma0 red">{this.state.errors["quantity"]}</p>

            <div className={this.state.selectedQuantity === "1-10" || this.state.selectedQuantity === "11-100" ? "dn" : "db pt4"}>
              <p className="mb2 f5-l f6">Контактные данные</p>
              <div className="flex justify-between flex-row-l flex-column mb5">
                <div className="w-60-l mb3 mb0-l mr3-l">
                  <input
                    type="text"
                    name="clientName"
                    placeholder="Ваше имя"
                    className="contact__form_input outline-0 bn pa3 f5-l f6"
                    onChange={this.handleContactInfo}
                  />
                  <p className="f6 ma0 red">{this.state.errors["clientName"]}</p>
                </div>
                <div className="w-40-l">
                  <InputMask
                    mask="+7-(999)-999-99-99"
                    name="phoneNumber"
                    placeholder="+7-(___)-___-__-__"
                    maskChar=" "
                    className="contact__form_input outline-0 bn pa3 f5-l f6"
                    onChange={this.handleContactInfo}
                    inputMode="tel"
                  />
                  <p className="f6 ma0 red">{this.state.errors["phoneNumber"]}</p>
                </div>
              </div>
              <button
                type="submit"
                className="link link__try black f5 tc db center pt1 pb1 b mb4 outline-0 bn"
                disabled={this.state.loading}
              >
                Попробовать <br />
                <span className="f7 lh-title normal">бесплатно</span>
              </button>

              <p className="ma0 tc black-60 f7 w-70-l center">
                Нажимая на кнопку, вы даете согласие на обработку персональных
                данных и принимаете{" "}
                <a href="/policy" className="blue link">
                  политику конфиденциальности.
                </a>
              </p>
            </div>
          </div>
          <div className={this.state.selectedQuantity === "1-10" || this.state.selectedQuantity === "11-100" ? "contact__form_apps contact__form_apps--pfree" : "contact__form_apps bt b--light-gray"}>
            <p className={this.state.selectedQuantity === "1-10" || this.state.selectedQuantity === "11-100" ? "dn" : "db f7"}>Проверка штрафов личного автомобиля</p>     
            <p className={this.state.selectedQuantity === "1-10" || this.state.selectedQuantity === "11-100" ? "b f3-l f6  tl db" : "dn"}>Скорее всего вам подойдет мобильное приложение для проверки штрафов</p>
            <div className={this.state.selectedQuantity === "1-10" || this.state.selectedQuantity === "11-100" ? "flex justify-start flex-row-l flex-column  mb4" : "flex justify-center flex-row-l flex-column  mb2"}>
                <a
                  href="https://play.google.com/store/apps/details?id=kz.psengineering.okauto"
                  className="link download__button mr1-l"
                  target="_blank"
                >
                  <img alt="ad2" title="ad2" src={ad4} className="mb0" />
                </a>
                <a
                  href="https://apps.apple.com/kz/app/okauto-штрафы-пдд/id1218144486"
                  className="link download__button"
                  target="_blank"
                >
                  <img alt="ios2" title="ios2" src={ios4} className="mb0" />
                </a>
            </div>
            <button
              type="button"
              className={!this.state.smallBusiness && (this.state.selectedQuantity === "1-10" || this.state.selectedQuantity === "11-100") ?
              "link link__try link__try--large black f5 tc db b pv1 ph3 b mb4 outline-0 bn pointer" :
              "dn"}
              onClick={this.openSmallBusinessForm}
            >
              Продолжить заявку на бизнес сопровождение
            </button>
            <div className={this.state.smallBusiness && (this.state.selectedQuantity === "1-10" || this.state.selectedQuantity === "11-100")  ? "db" : "dn"}>
              <p className="mb2 f5-l f6 tl">Контактные данные</p>
              <div className="flex justify-between flex-row-l flex-column mb3">
                <div className="w-60-l mb3 mb0-l mr3-l">
                  <input
                    type="text"
                    name="clientName"
                    placeholder="Ваше имя"
                    className="contact__form_input outline-0 bn pa3 f5-l f6"
                    onChange={this.handleContactInfo}
                  />
                  <p className="tl f6 ma0 red">{this.state.errors["clientName"]}</p>
                </div>
                <div className="w-40-l">
                  <InputMask
                    mask="+7-(999)-999-99-99"
                    name="phoneNumber"
                    placeholder="+7-(___)-___-__-__"
                    maskChar=" "
                    className="contact__form_input outline-0 bn pa3 f5-l f6"
                    onChange={this.handleContactInfo}
                    inputMode="tel"
                  />
                  <p className="tl f6 ma0 red">{this.state.errors["phoneNumber"]}</p>
                </div>
              </div>
              <button
                type="submit"
                className="link link__try link__try--large black f5 tc db center pt1 pb1 b mb4 outline-0 bn ph3" 
                disabled={this.state.loading}
              >
                <span className="db-l dn">Оставить заявку для бизнеса</span> 
                <span className="dn-l db">Заявка для бизнеса</span> 
                <span className="f7 lh-title normal">бесплатно</span>
              </button>

              <p className="ma0 tc black-60 f7 w-70-l center">
                Нажимая на кнопку, вы даете согласие на обработку персональных
                данных и принимаете{" "}
                <a href="/policy" className="blue link">
                  политику конфиденциальности.
                </a>
              </p>
            </div>
          </div>
        </form>
        <div className={this.state.showPopup ? "contact__form_popup" : "dn"}>
          <a
            className="absolute top-2 right-2 pointer"
            onClick={this.closePopup}
          >
            <img alt="close" title="close" src={closeNew} className="ma0" />
          </a>
          <img alt="tick" title="tick" src={tickLarge} />
          <h1 className="main__header center">Мы приняли заявку</h1>
          <p className="f4">
            Скоро свяжемся с вами, чтобы организовать доступ к нашему сервису и
            помочь разобраться
          </p>
          <div className="mt3 tc">
            <a
              className="dib v-mid"
              href="https://www.instagram.com/okauto.kz/"
              target="_blank"
            >
              <img
                alt="ig"
                title="ig"
                src={igBlack}
                className="w1 h1 ml2 mr2"
              />
            </a>
            <a
              className="dib v-mid"
              href="https://www.facebook.com/okauto.kz/"
              target="_blank"
            >
              <img
                alt="fb"
                title="fb"
                src={fbBlack}
                className="w1 h1 ml2 mr2"
              />
            </a>
            <a className="dib v-mid">
              <img
                alt="yb"
                title="yb"
                src={ybBlack}
                className="w1 h1 ml2 mr2"
              />
            </a>
            <a className="dib v-mid">
              <img
                alt="vk"
                title="vk"
                src={vkBlack}
                className="w1 h1 ml2 mr2"
              />
            </a>
            <a
              className="dib v-mid"
              href="https://api.whatsapp.com/send?phone=77075001122"
              target="_blank"
            >
              <img
                alt="wp"
                title="wp"
                src={wpBlack}
                className="w1 h1 ml2 mr2"
              />
            </a>
          </div>
          <p className="f7">Проверка штрафов личного автомобиля</p>    
          <div className="flex justify-center justify-start flex-row-l flex-column mb2">
              <a
                href="https://play.google.com/store/apps/details?id=kz.psengineering.okauto"
                className="link download__button mr1-l"
                target="_blank"
              >
                <img alt="ad2" title="ad2" src={ad4} className="mb0" />
              </a>
              <a
                href="https://apps.apple.com/kz/app/okauto-штрафы-пдд/id1218144486"
                className="link download__button"
                target="_blank"
              >
                <img alt="ios2" title="ios2" src={ios4} className="mb0" />
              </a>
          </div>
        </div>
      </div>
    )
  }
}

export default ContactForm
